
import React ,{ Component } from 'react';
import {Container, Row, Col,Image,Button,Form  } from 'react-bootstrap';
import "../Volunteer/VolunteerSection.css";
import { withRouter,Link } from "react-router-dom";
import loader from '../../images/loader.gif';
import SelectBox from '../SelectDropDown/SelectDropDown';
import { components } from 'react-select';
import { getAPICallDataForOnlyBSA } from '../../services/MemberService';
import CardLessDataTableWithOutSearch from '../datatables/CardLessDataTableWithOutSearch';

const DropdownIndicator = props => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-6q0nyr-Svg"><path fill="currentColor" d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
            </components.DropdownIndicator>
        )
    );
  };

class PendingRenewalSection extends Component {
    constructor(props) {
        super(props)

        this.state = {
            chatID: window.User !=undefined ? window.User.chatID : "943827154",
            userAccessList:props?.location?.params?.userAccessList == undefined ? "" : props?.location?.params?.userAccessList,
            accessName:props?.location?.params?.accessName == undefined ? "" : props?.location?.params?.accessName,
            roleName:props?.location?.params?.roleName == undefined ? "" : props?.location?.params?.roleName,

            boothValue:"",
            boothListArr:[],
            boothId:props?.location?.params?.boothId == undefined ? "" : props?.location?.params?.boothId,
            validateboothId:"",
            getBoothsLoadingSymbol:"hide",

            pendingRenewalLoadingSymbol:"show",
            pendingRenewalMembersArr:[],
            pendingRenewalMembersDataCheck:0,
            boothSelectedValue:"",
            pendingRenewalMemberBlockShow:false,
            searchNamePendingRenewalValue:"",
            membershipTypeValue:props?.location?.params?.enrollTypeStr == undefined ? "" : props?.location?.params?.enrollTypeStr
        }
    }

    componentDidMount() {
        if(this.state.userAccessList == ""){
            this.props.history.push("/dashboard");
        }else{

            this.serchOptionGetBooths(this.state.userAccessList.constituencyList[0].id);

            /*if(this.state.userAccessList.boothList != null && this.state.userAccessList.boothList.length>0){
                let arr1=[];
                this.state.userAccessList.boothList.map(item => {
                    const obj = {
                        value: item.id,
                        label: "Booth No -"+item.name,
                    }
                    arr1.push(obj);
                    
                });
                this.setState({ boothListArr: arr1 });
                

                console.log("this.state.boothId",this.state.boothId)
                console.log("this.state.boothId",this.state.boothListArr)

                setTimeout(() => {
                    if(this.state.boothId != ""){
                        if(this.state.boothListArr.length>0){
                            for(var i in arr1){
                                if(arr1[i].value == this.state.boothId){
                                    this.setState({
                                            boothValue: {
                                            value: arr1[i].value,
                                            label: arr1[i].label
                                        }
                                    });
                                    this.setState({ boothId: arr1[i].value});
    
                                    this.getRenewalPendingCadreDetailsInABooth(arr1[i].value);
                                    this.setState({pendingRenewalMemberBlockShow:true})
                                    
                                }
                            }
                        }
                    }
                }, 600);

                
                
               
            }*/

    
        }
    }

    serchOptionGetBooths = (value) => {
        this.setState({ boothListArr: [],boothsLoadingSymbol:"show"});
        this.setState({volunteerSearchBoothName:"",
        SearchBoothValue:""})
    
        const obj =  
        JSON.stringify({ 
            "chatId" : this.state.chatID,
            "constituencyId" : value
           
        });
    
    

        getAPICallDataForOnlyBSA(obj,"getBoothsByConstituencyId")
          .then((res) => res.json())
          .then((json) => {
            this.setState({ boothsLoadingSymbol:"hide"});
              if(json !=null && json.length>0){
                var boothArr=[];
                for(var i in  json){
                    var obj={'value':json[i].id,'label':"Booth-"+json[i].name};
                    boothArr.push(obj)
                    
                }
               
                this.setState({ boothListArr:boothArr });

                setTimeout(() => {
                    if(this.state.boothId != ""){
                        if(this.state.boothListArr.length>0){
                            for(var i in boothArr){
                                if(boothArr[i].value == this.state.boothId){
                                    this.setState({
                                            boothValue: {
                                            value: boothArr[i].value,
                                            label: boothArr[i].label
                                        }
                                    });
                                    this.setState({ boothId: boothArr[i].value});
    
                                    
                                    if(this.state.membershipTypeValue == 2){
                                        this.getRenewalPendingCadreDetailsInABooth(boothArr[i].value);
                                    }else if(this.state.membershipTypeValue == 1){
                                        this.getVoterDetailsForNewEnrollmentInABooth(boothArr[i].value);
                                    }

                                    this.setState({pendingRenewalMemberBlockShow:true})
                                    
                                }
                            }
                        }
                    }
                }, 600);

              }
            
          });
      }

    handleOnchangeSelectBoxForFamily = (ename, evalue) =>{
        const name = ename;
        const value = evalue;
    
        if(name == "boothId"){
            let getBoothIdName = this.state.boothListArr.find((a) => a.value == value);
            this.setState({
                boothValue: {
                value: getBoothIdName.value,
                label: getBoothIdName.label
            }
        });

        if(this.state.membershipTypeValue == 2){
            this.getRenewalPendingCadreDetailsInABooth(value);
        }else if(this.state.membershipTypeValue == 1){
            this.getVoterDetailsForNewEnrollmentInABooth(value);
        }
        
        this.setState({pendingRenewalMemberBlockShow:true})
        }
          
    
        this.setState({ [name]: value});
      }

      getVoterDetailsForNewEnrollmentInABooth = (boothId) => {
        this.setState({pendingRenewalLoadingSymbol:"show",pendingRenewalMembersArr:[],pendingRenewalMembersDataCheck:0});

        const BGObj =  
              JSON.stringify({ 
                "chatId" :this.state.chatID,
                "boothId" : boothId
              }); 
              getAPICallDataForOnlyBSA(BGObj,"Cadre/getVoterDetailsForNewEnrollmentInABooth")
              .then((res) => res.json())
              .then((content) => {
                this.setState({pendingRenewalLoadingSymbol:"hide"});
                if(content !=null && content.length>0){
                    this.setState({boothSelectedValue:this.state.boothValue.label+ " : "+ content.length + " Pending New Enroll Count"})
                    this.setState({pendingRenewalMembersArr:content,pendingRenewalMembersDataCheck:0});
                   
                }else{
                    this.setState({pendingRenewalMembersArr:[],pendingRenewalMembersDataCheck:1});
                }
                
              }); 
      }

      getRenewalPendingCadreDetailsInABooth = (boothId) => {
        this.setState({pendingRenewalLoadingSymbol:"show",pendingRenewalMembersArr:[],pendingRenewalMembersDataCheck:0});

        const BGObj =  
              JSON.stringify({ 
                "chatId" :this.state.chatID,
                "boothId" : boothId
              }); 
              getAPICallDataForOnlyBSA(BGObj,"Cadre/getRenewalPendingCadreDetailsInABooth")
              .then((res) => res.json())
              .then((content) => {
                this.setState({pendingRenewalLoadingSymbol:"hide"});
                if(content !=null && content.length>0){
                    this.setState({boothSelectedValue:this.state.boothValue.label+ " : "+ content.length + " Pending Renewal Count"})
                    this.setState({pendingRenewalMembersArr:content,pendingRenewalMembersDataCheck:0});
                   
                }else{
                    this.setState({pendingRenewalMembersArr:[],pendingRenewalMembersDataCheck:1});
                }
                
              }); 
      }

      actionsFormatterForPendingRenewalMemBind = (cell, row, rowIndex, formatExtraData) => {

  
        return (
            <span style={{textAlign:"left",marginLeft:"0px"}}>{(row.Name)} 
                <><br/><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>MID - {(row.MembershipId)}</span></>
                <><br/><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>VCN - {(row.voterCardNo)}</span></>
                <><br/><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>Gender - {(row.Gender)}</span></>
                <><br/><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>Age - {(row.Age)}</span></>
    
                
            </span>
         );
        
    }

    
    actionsFormatterForPendingRenMobileBind = (cell, row, rowIndex, formatExtraData) => {

  
    return (
        <span style={{textAlign:"left",marginLeft:"0px"}}><i className="fa-solid fa-phone" style={{color:"green"}} ></i> <a  style={{color:"green"}} href={`tel:${row.MobileNo}`}> {row.MobileNo}</a>
        
        </span>
     );
    
}

actionsFormatterForPendingRenewalNowBind = (cell, row, rowIndex, formatExtraData) => {
    return (
        <span className='color_red' onClick={() => this.getRenewalNow(row)} style={{textAlign:"left",marginLeft:"0px",textDecoration:"underline",cursor:"pointer",fontWeight:"bold"}}>Renewal Now
        </span>
     );
}


      pendingRenewalMembersColumns() {
        let columns = [
            
            {
                dataField: "Name",
                text: "Name",
                sort: true,
                formatter: this.actionsFormatterForPendingRenewalMemBind.bind(this),
                headerStyle: {
                    width: "50%"
                }
            },
            {
                dataField: "MobileNo",
                text: "MNo",
                sort: true,
                formatter: this.actionsFormatterForPendingRenMobileBind.bind(this),
                headerStyle: {
                    width: "25%"
                }
            },
            /*{
                dataField: "Gender",
                text: "G",
                sort: true,
                headerStyle: {
                    width: "10%"
                }
            },
           {
                dataField: "Age",
                text: "Age",
                sort: true,
                headerStyle: {
                    width: "10%"
                }
            },*/
            {
                 dataField: "Action",
                 text: "Action",
                 formatter: this.actionsFormatterForPendingRenewalNowBind.bind(this),
                 headerStyle: {
                     width: "20%"
                 }
             }
        
        ];
        return columns;
    }


    actionsFormatterForPendingNewMembershipBind = (cell, row, rowIndex, formatExtraData) => {

  
        return (
            <span style={{textAlign:"left",marginLeft:"0px"}}>{(row.Name)} 
                <><br/><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>VCN - {(row.voterCardNo)}</span></>
                <><br/><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>Gender - {(row.Gender)}</span></>
                <><br/><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>Age - {(row.Age)}</span></>
                <><br/><span className='f-10 color_red' style={{textAlign:"left",marginLeft:"0px"}}>House No - {(row.houseNo)}</span></>
    
                
            </span>
         );
        
    }

    actionsFormatterForPendingNewMembershipEnrollActionBind = (cell, row, rowIndex, formatExtraData) => {
        return (
            <span className='color_red' onClick={() => this.getNewMemberShipNow(row)} style={{textAlign:"left",marginLeft:"0px",textDecoration:"underline",cursor:"pointer",fontWeight:"bold"}}>Enroll Now
            </span>
         );
    }

    pendingNewMembersColumns() {
        let columns = [
            {
                dataField: "SNO",
                text: "SNO",
                sort: true,
                headerStyle: {
                    width: "10%"
                }
            },
            {
                dataField: "Name",
                text: "Name",
                sort: true,
                formatter: this.actionsFormatterForPendingNewMembershipBind.bind(this),
                headerStyle: {
                    width: "50%"
                }
            },
            
            {
                 dataField: "Action",
                 text: "Action",
                 formatter: this.actionsFormatterForPendingNewMembershipEnrollActionBind.bind(this),
                 headerStyle: {
                     width: "20%"
                 }
             }
        
        ];
        return columns;
    }
    
    
    pendingRenewalMembersDataDisplay = (cadreData) =>{
        console.log(cadreData)
    let dataArr2 = [];
        if(cadreData.length>0){
            
            for (let i = 0; i < cadreData.length; i++) {

                const cname = (cadreData[i].cname);
                const mid = (cadreData[i].mid);
                const mobileNo = (cadreData[i].mobileNo);
                const voterCardNo = (cadreData[i].vno);
                const age = (cadreData[i].age);
                const gender = (cadreData[i].gender);
                
               
                
                const obj = {
                    Name: cname ,
                    MembershipId: mid,
                    MobileNo: mobileNo ,
                    voterCardNo: voterCardNo,
                    Age: age ,
                    Gender: gender
                }
                dataArr2.push(obj);
            } 

            
            
            return dataArr2;
        }
    }

    pendingNewMembersDataDisplay = (cadreData) =>{
        console.log(cadreData)
        let dataArr2 = [];
            if(cadreData.length>0){
                
                for (let i = 0; i < cadreData.length; i++) {

                    const cname = (cadreData[i].personName);
                    const houseNo = (cadreData[i].houseNo);
                    const SNO = (cadreData[i].serialNo);
                    const voterCardNo = (cadreData[i].vno);
                    const age = (cadreData[i].age);
                    const gender = (cadreData[i].gender);
                    
                
                    
                    const obj = {
                        Name: cname ,
                        houseNo: houseNo,
                        SNO: SNO ,
                        voterCardNo: voterCardNo,
                        Age: age ,
                        Gender: gender
                    }
                    dataArr2.push(obj);
                } 

                
                
                return dataArr2;
            }
        }
  
    
    handlesearchNamePendingRenewalValueChange = (event) => {
    this.setState({
        searchNamePendingRenewalValue: event.target.value
    })
}

getRenewalNow = (data) => {
    this.props.history.push({
        pathname: "/renewal_page_reg",
        params: {
            navbarDisplay:"hide",
            mid:data.MembershipId,
            vno:data.voterCardNo,
            userAccessList:this.state.userAccessList,
            accessName:this.state.accessName,
            roleName:this.state.roleName,	
            boothId:this.state.boothId,
            enrollTypeValue:"renewal",
          },
      });
}


getNewMemberShipNow = (data) => {
    this.props.history.push({
        pathname: "/renewal_page_reg",
        params: {
            navbarDisplay:"hide",
            mid:data.MembershipId,
            vno:data.voterCardNo,
            userAccessList:this.state.userAccessList,
            accessName:this.state.accessName,
            roleName:this.state.roleName,	
            boothId:this.state.boothId,
            enrollTypeValue:"newEnroll"
          },
      });
}

getMembershipType = (value) => {
   this.setState({membershipTypeValue:value});

   this.setState({ boothValue:"",boothId:""})
   this.setState({pendingRenewalMembersArr:[],pendingRenewalMembersDataCheck:0});
    

   this.serchOptionGetBooths(this.state.userAccessList.constituencyList[0].id);
}

      
    render() {
        console.log("roleName",this.state.roleName)

        let pendingRenewalManualSearchArr=[];
        let keyword = this.state.searchNamePendingRenewalValue.toString()
        if (keyword !== ''){
            pendingRenewalManualSearchArr = this.state.pendingRenewalMembersArr.filter(  (item) => {
                
                if (item.vno.toLowerCase().startsWith(keyword.toLowerCase())) {
                    return item;
                }
                if(typeof item.cname != "undefined"){
                    if (item.cname.toLowerCase().startsWith(keyword.toLowerCase())) {
                        return item;
                    }
                    if (item.mid.toString().startsWith(keyword.toLowerCase())) {
                        return item;
                    }
                    if (item.mobileNo.toString().startsWith(keyword.toLowerCase())) {
                        return item;
                    }
                }
               
                if (item.gender.toString().startsWith(keyword.toLowerCase())) {
                    return item;
                }
                if (item.age.toString().startsWith(keyword.toLowerCase())) {
                    return item;
                }
                
                if(typeof item.personName != "undefined"){
                    if (item.personName.toLowerCase().startsWith(keyword.toLowerCase())) {
                        return item;
                    }
                    if (item.houseNo.toLowerCase().startsWith(keyword.toLowerCase())) {
                        return item;
                    }
                    if (item.serialNo.toString().startsWith(keyword.toLowerCase())) {
                        return item;
                    }
                }
                
              
                
            } );
        }else{
            pendingRenewalManualSearchArr = this.state.pendingRenewalMembersArr;
            
        }

        return (
            <section className="mb-4">
                <div className='border_bottom_red_new'>
                     <Image variant="top" src={require('../../images/membership_logo.png')} className='w-100' />
                </div>

                <div className='bg_yellow_cadre'>   
            
                    <Container className="mb-6">
                        <Row>
                            <Col sm={12} className='mt-3'>
                            <Link to={{pathname: "/dashboard"}}> 
                                <Button variant="outline-danger pad5_14" className='f-12  fw-bold'> <i class="fa-solid fa-house"></i> Home</Button> 
                            </Link>
                            </Col>
                        </Row>
                        <Row>
                        <Col>
                            {/*<h6 className='text-center color_green fw-bold  mt-3'><span className='border-bottom-double'>PENDING ENROLLMENTS </span></h6>*/}
                            <h6 className='text-center color_green fw-bold  mt-3'><span className='border-bottom-double'>{this.state.accessName}</span> </h6>
                        </Col>
                    </Row>


                    <Row>
                        <Col sm={12} className='mt-2'>
                            <ul className='list-inline eventStatusLiCls mt-3'>
                                <li className={this.state.membershipTypeValue == 1 ? "active red" : ""} onClick={() => this.getMembershipType(1)}>VOTERS WITH OUT MEMBERSHIP</li>
                                <li className={this.state.membershipTypeValue == 2 ? "active yellow" : ""} onClick={() => this.getMembershipType(2)}>RENEWAL <br/>PENDINGS</li>
                            </ul>
                        </Col>
                    </Row>

                    {
                        this.state.membershipTypeValue == 1 ?
                        (
                            <Row>
                                <Col>
                                    <h6 className='text-center color_red fw-bold  mt-3'> <span className='border-bottom-double'>VOTERS WITH OUT MEMBERSHIP</span></h6>
                                </Col>
                            </Row>
                        )
                        :
                        (
                            this.state.membershipTypeValue == 2 ? 
                            (
                                <Row>
                                    <Col>
                                        <h6 className='text-center color_red fw-bold  mt-3'> <span className='border-bottom-double'>RENEWAL PENDINGS</span></h6>
                                    </Col>
                                </Row>
                            )
                            :
                            ("")
                        )
                    }

                    {
                        this.state.membershipTypeValue != "" ?
                        (
                            <>
                                 <Row>
                                    <Col className='mt-3'>
                                        <label className="form-label f-12 fw-bold">Select Booth<span className='color_red f-14 ms-1'>*</span><span className='f-12 color_red'>{this.state.validateboothId}</span>
                                            {
                                                this.state.getBoothsLoadingSymbol == "show" ?
                                                (<Image src={loader} alt="loader" className='loader_cls ms-2 float-end'></Image>)
                                                :
                                                ("")
                                            }
                                            
                                        </label>
                                        <SelectBox
                                                stateData={this.state.boothValue}
                                                handleChange={this.handleOnchangeSelectBoxForFamily}
                                                name="boothId"
                                                options={this.state.boothListArr}
                                                placeholderName={'Booth'}
                                                dropdownIndicator={{ DropdownIndicator }}
                                            />
                                    </Col>
                                </Row>

                                {
                                    this.state.pendingRenewalMemberBlockShow == true ?
                                    (
                                        <>
                                        {
                                            this.state.pendingRenewalLoadingSymbol == "show" ?
                                            (
                                                <Row>
                                                    <Col sm={12}>
                                                        <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                                                    </Col>
                                                </Row>
                                            )
                                            :
                                            ("")
                                        }
                        
                                        <Row>
                                            <Col sm={12}>
                                        
                                            <div className='bg_light_blue mt-3'>
                                                    <Row>
                                                        <Col sm={6}>
                                                            <input
                                                                className='float-end search_bar mt-2'
                                                                type="search"
                                                                placeholder='Search'
                                                                value={this.state.searchNamePendingRenewalValue}
                                                                onChange={this.handlesearchNamePendingRenewalValueChange}
                                                            />
                                                            <i className='fa fa-search search-icon'></i>
                                                        </Col>
                                                    </Row>
                        
                                                    <>
                                                        {
                                                            pendingRenewalManualSearchArr.length>0 ?
                                                            (
                                                                
                                                            
                                                                <CardLessDataTableWithOutSearch 
                                                                    parentData={this.state.membershipTypeValue == 2 ? this.pendingRenewalMembersDataDisplay(pendingRenewalManualSearchArr) : this.pendingNewMembersDataDisplay(pendingRenewalManualSearchArr) }
                                                                    particlecolumns={this.state.membershipTypeValue == 2 ? this.pendingRenewalMembersColumns() : this.pendingNewMembersColumns()}
                                                                    defaultSorted={[{
                                                                        dataField: "name",
                                                                        order: "asc"
                                                                    }]}
                                                                tableHeading={this.state.boothSelectedValue}
                                                                />
                                                                                
                                                                                    
                                                                
                                                            )
                                                            :
                                                            (<>
                                                                {
                                                                this.state.pendingRenewalMembersDataCheck == 1 ?
                                                                (<h6 className='f-14 text-center mt-4'>No Pending Renewals...</h6>)
                                                                :
                                                                ("")
                                                            }
                                                            </>)
                                                            }
                                                        </>
                        
                                                        
                                                </div>
                                                                        
                                            </Col>
                                        </Row>
                                        </>
                        
                                    )
                                    :
                                    ("")
                                }
                            </>
                        )
                        :
                        ("")
                    }

                   

                
                   
                    

            </Container>
        </div>  

            
            </section>
        )
    }
  }
  
  export default withRouter(PendingRenewalSection);
  